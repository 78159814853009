// extracted by mini-css-extract-plugin
export var RecruitmentDetail = "FSRecruitmentDetail-module--RecruitmentDetail--89756";
export var button = "FSRecruitmentDetail-module--button--7b3e1";
export var contentBox = "FSRecruitmentDetail-module--contentBox--0226c";
export var contentItem = "FSRecruitmentDetail-module--contentItem--4776a";
export var contentItemLeft = "FSRecruitmentDetail-module--contentItemLeft--ce48f";
export var detailedInstructions = "FSRecruitmentDetail-module--detailedInstructions--05003";
export var detailedInstructionsTitle = "FSRecruitmentDetail-module--detailedInstructionsTitle--0b6f3";
export var jobBaseIntroMobile = "FSRecruitmentDetail-module--jobBaseIntroMobile--50b92";
export var jobBaseIntroPc = "FSRecruitmentDetail-module--jobBaseIntroPc--d34ee";
export var jobTitle = "FSRecruitmentDetail-module--jobTitle--e66a4";
export var preClass = "FSRecruitmentDetail-module--preClass--34adc";
export var socialRecruitmentDetailPage = "FSRecruitmentDetail-module--socialRecruitmentDetailPage--75da8";